












import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'
import VLoadSpinner from '~/components/VLoadSpinner.vue'
import { verifyEmail } from '~/api/auth'
import { analyticsSignupCompleted } from '../../services/analytics'

@Component({ components: { VLoadSpinner } })
export default class ActivationPage extends Vue {
    @Prop() confirmKey!: string

    isLoading = true
    isSuccess = false

    async verifyEmail() {
        try {
            await verifyEmail(this.confirmKey)
            this.isSuccess = true
            setTimeout(() => Vue.router.push({ name: 'Home' }), 2000)
        } finally {
            this.isLoading = false
        }
    }

    created() {
        this.verifyEmail()
    }
}
